import { motion } from "framer-motion";
const Tokenomics = () => {

    const container = {
        hidden: { opacity: 1, scale: 0 },
        visible: {
            opacity: 1,
            scale: 1,
            transition: {
                delayChildren: 0.5,
                staggerChildren: 0.5
            }
        }
    }

    const item = {
        hidden: { y: 20, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1,
            transition: { duration: 0.5 }
        }
    }

    const tokenData = [
        {
            id: 1,
            title: '0% Buy & Sell',
            body: 'Agent 007 available on Raydium with 0% BUY and SELL taxes'

        },
        {
            id: 2,
            title: 'Charts',
            body: 'Agent 007 Live trading is live on Dexscreener'

        },
        {
            id: 3,
            title: 'Secure & Verified',
            body: '100% Score Awarded by Snifer Token Scure and Verified Contract'

        },
    ]


    return (
        <motion.div className="w-[90%] py-10 px-4 md:w-[80%] flex flex-col items-center mx-auto"
            variants={container}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
        >
            <div className="bg-[#16C8D6] inline-block h-[2px] mx-auto w-[10%] my-10"
                variants={item}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
            />
            <div className="flex flex-col gap-5 md:flex-row" >
                {
                    tokenData.map(item => ((
                        <motion.div className=" hover:text-[#000] rounded-lg transition-all duration-300 hover:bg-[#fff] text-[#fff] border-[2px] border-[#fff] py-5 md:py-10 cursor-pointer px-5  flex items-center text-center flex-col gap-3" key={item.id}

                            variants={item}
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}

                        >
                            <img className="w-[35px] " src={`../img/svg-${item.id}.svg`} alt="svg" />
                            <h1 className=" font-semibold text-[20px]">{item.title}</h1>
                            <p className="font-[300]">{item.body}</p>
                        </motion.div>
                    )))
                }
            </div >

           

        </motion.div>
    );
}

export default Tokenomics;