import { FaWallet } from 'react-icons/fa'
import { AnimatePresence, motion } from 'framer-motion';
import { slideIn } from "../utils/motion";
import { useRef, useState } from 'react';
import SkewedImage from '../utils/SkewedImage';
import MarqueeImage from '../utils/MarqueeImage';



const Buy = () => {
    return (<div id="tokenomics" className="px-4 bg-[#0e0f10]  w-full py-20 flex flex-col justify-center items-start gap-5">

        <div className='flex-row flex gap-5 w-[90%] md:w-[80%] mx-auto'>
     

            <a href="https://t.me/agent007onsol">
                <motion.button className='bg-[#F8F93C]  transition-all duration-300  transform p-5 rounded-full '
                         
                         whileHover={{scale: 1.1}}
      
                >
                    Join our Telegram
                </motion.button>
            </a><a href="https://x.com/agent007onsol">
                 <motion.button className='bg-[#16C8D6] transform hover:scale-110 duration-300 transition-all p-5 rounded-full '
                    
                      whileHover={{scale: 1.1}}
                    
                 > Follow our X
                </motion.button>
            </a>

        </div>

    </div>);
}

export default Buy;