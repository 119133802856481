import { useState, useRef } from "react";
import { motion } from 'framer-motion';
import { loaderVariants, slideIn } from "../utils/motion";
import { FaTelegram } from "react-icons/fa";

import { AiFillTwitterCircle, AiOutlineCopy, AiOutlineArrowRight, AiOutlineTwitter, AiOutlineRight, AiOutlineWallet } from "react-icons/ai"
import Button from "../utils/Button";
import AnimeLogo from "../utils/AnimeLogo";
import CA from "../utils/Ca";

const Hero = () => {


    const [isCopied, setIsCopied] = useState(false);
    const handleCopied = () => {
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 5000)
    }

    const contractRef = useRef();

    const copyContractText = () => {
        const text = contractRef.current.textContent;
        navigator.clipboard.writeText(text);
        console.log('Text copied: ' + text);
        handleCopied();
    };


    return (<div className="bg-[#000] relative hero-section w-[100vw]  flex mx-auto flex-col px-4 pt-0 gap-5">


        <div className="flex flex-col z-[20] w-[90%] py-[10%] md:w-[80%] mx-auto  md:flex-row text-[#fff] items-center justify-between">
            
            <motion.div className="flex flex-col gap-5"
                variants={slideIn('left', 'spring', 0.1, 0.8)}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
            >
                <h1 className="text-[45px] font-[700] md:text-[75px] ">
                Agent 007
                </h1>
                <p className="w-full md:w-[60%] font-[300]">
                $007 is inspired by the award winning action shooting movie of all time. 007 - James Bond.

                    
                </p>
                <div className="flex flex-col gap-3">
                    <div className="flex flex-row gap-3"><a href="https://twitter.com/agent007onsol"><button className="bg-[#016fff] hover:scale-90 transform flex flex-row items-center gap-1 cursor-pointer rounded-lg transition-all duration-300  self-start px-5 py-1 text-[#fff] " ><AiOutlineTwitter /> Follow our X</button></a>
                    <a href="https://t.me/agent007onsol"><button className="border-2 text-[#016fff] border-[#016fff] hover:scale-90 transform flex flex-row items-center gap-1 cursor-pointer rounded-lg transition-all duration-300  self-start px-5 py-1  " ><AiOutlineArrowRight /> Telegram</button></a>
                    </div>
                   
                    <a href="#Raydium"><button className="bg-[#F8F93C] hover:scale-90 transform flex flex-row items-center gap-1 cursor-pointer rounded-lg transition-all duration-300  self-start px-5 py-1 text-[#000]" ><AiOutlineWallet /> Buy on Raydium</button></a>
                </div>
            </motion.div>
            <div>
                <motion.div className="rounded-full"
                    variants={slideIn('right', 'spring', 0.7, 0.8)}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                >

                    <AnimeLogo imgUrl="giphy.gif" classProps="" />
                </motion.div>
            </div>
        </div>




        {/* <div className="flex flex-col text-center  gap-5 items-center justify-between z-[20]">
            <motion.div className="rounded-full"
                variants={slideIn('down', 'spring', 0.1, 0.3)}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
            >

               <AnimeLogo imgUrl="logo.png" classProps="rounded-full" />
            </motion.div>
            <h1 className="text-[35px] md:text-[75px]  font-bold ">$Headset</h1>
            <motion.div className="w-full  p-2 items-center justify-center gap-5 flex flex-row text-[#fff]"
                variants={slideIn('down', 'spring', 0.5, 0.4)}
                initial="hidden"
                whileInView="visible" viewport={{ once: true }}
            >
              <CA boxClass="bg-[#fff] rounded-lg" textClass="text-[#000]" contractaddress="Contract address to be released" />
            </motion.div>

            <p className="w-full ">Inspired by everyday music enthusiasts enjoying every hertz of beats produced by the headsets</p>

            <div className="flex flex-row gap-3 items-center font-bold">
                <a href="https://t.me/ElonRektZuckerberg">
                    <Button text="Ape $Headsets" scale="in"   classProps="bg-[#FD7F04] border-[#FE5503] text-[#110710]" />
                </a>
                <a href="#">
                    <Button 
                        text="Follow our Twitter"
                        classProps="bg-[#FD7F04] border-[#FE5503] text-[#110710]"
                        scale="in"
                    
                    />
                </a>

            </div>
            <motion.div className="w-full gap-5 flex flex-col md:flex-row items-center justify-center"
            >
                <motion.div
                    variants={slideIn('left', 'spring', 0.4, 0.3)}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    whileHover={{ scale: 1.03, rotate: -1 }}
                    className="w-full  md:w-1/2 rounded-lg border-[3px] border-[#83170F] p-2">
                    <img src="../img/elon.jpg" className=" brightness rounded-lg" alt="elon" />
                </motion.div>
                <motion.div
                    variants={slideIn('right', 'spring', 0.7, 0.3)}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    whileHover={{ scale: 1.03, rotate: 1 }}
                    className="w-full md:w-1/2 rounded-lg border-[3px] border-[#E7941E] p-2">
                    <img src="../img/zuck.jpg" className="brightness rounded-lg" alt="zucks" />
                </motion.div>
            </motion.div>

        </div> */}


    
    </div>);
}

export default Hero;