import { useState, useRef } from "react";
import { motion } from 'framer-motion';
import { slideIn } from "../utils/motion";
import { FaTelegram } from "react-icons/fa";
import { AiFillTwitterCircle, AiOutlineCopy } from "react-icons/ai"
import MarqueeImage from "../utils/MarqueeImage";
import CA from "../utils/Ca";



const About = () => {
   
    const [canPlay, setCanPlay] = useState(false);

    return (<div className="w-full relative border-b-2  mt-10 pt-20 bg-[#000] ">
     
        <div className="  text-[#fff] bg-opacity-80">
            <div className=" transform  bg-opacity-70 pb-5 flex  flex-col justify-center items-center gap-10">
                <motion.div
                         variants={slideIn('left', 'spring', 0.1, 0.4)}
                         initial="hidden"
                         whileInView="visible"
                         viewport={{ once: true }}
                >
                    <img src="../img/logo.png" className="w-[80%] mx-auto md:w-[180px] object-cover" alt="hero" />
                </motion.div>
                <div className="flex flex-col  gap-5 items-center justify-between px-4 w-[90%] md:w-[70%]">
                    <motion.div className="flex  flex-col gap-5 w-full items-center justify-center text-center"
                    >
                        <div className="flex flex-col gap-5">
                            <motion.h1 className="font-semibold text-[25px] uppercase leading-normal"
                                 variants={slideIn('right', 'spring', 0.5, 0.4)}
                                 initial="hidden"
                                 whileInView="visible"
                                 viewport={{ once: true }}
                            >Contract Address</motion.h1>
                            <motion.a href="#" target="_blank"
                                 variants={slideIn('right', 'spring', 0.5, 0.4)}
                                 initial="hidden"
                                 whileInView="visible"
                                 viewport={{ once: true }}
                            >
                                <CA contractaddress="8vBSUMj4G4UGi88vuMQjcjLHnqoQByEJnTANRm6CbNMV" boxClass=" " textClass="font-light text-[#16C8D6]" />
                            </motion.a>
                            <motion.p className="w-full mx-auto"
                                 variants={slideIn('down', 'spring', 0.8, 0.5)}
                                 initial="hidden"
                                 whileInView="visible"
                                 viewport={{ once: true }}
                            >$007 is inspired by the award winning action shooting movie of all time. 007 - James Bond.

                            Hop in with us, if you are ready to shoot down soljeets.</motion.p>
                        
                        </div>
                    
                    </motion.div>
                </div>
             
            </div>
        </div>
     <img src="../img/hero.jpg" alt="hero" className="w-full" />
    </div>);
}

export default About;