import { motion } from 'framer-motion';
import { slideIn } from "../utils/motion";
import { AiFillInstagram, AiFillYoutube, AiOutlineArrowRight, AiOutlineTwitter } from 'react-icons/ai';
import Button from '../utils/Button';
import { SiTelegram } from 'react-icons/si';

const Header = () => {

    return (<motion.div className="bg-[#000] w-full text-[#fff] py-5 md:py-1  text-[16px] font-semibold flex  md:flex-row z-20 gap-5 items-center  justify-between md:justify-between px-10"
        variants={slideIn('up', 'spring', 0.1, 0.4)}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}

    >
        <div className=' flex flex-row items-center justify-start rounded-full'>
            <img className='w-[70px] uppercase rounded-full' src="../img/logo.png" alt="logo" />
            <h1>agent 007</h1>
        </div>
        <motion.div className=' hidden md:flex flex-row gap-10 items-center  justify-center'
        >
            <a href="https://t.me/agent007onsol">

                <button className='flex-row flex gap-1 items-center rounded-lg text-[#000] px-3 py-1 hover:bg-[#000] transition-all duration-300 hover:text-[#fff]'>
                    <div className='relative p-[2px]'>
                        <div className='absolute inset-0 rounded-lg bg-gradient-to-r from-[#384cfa] to-[#eb5103] via-[#efca44]' />
                        <div className='relative rounded-lg p-2 bg-[#fff] text-[#000] flex items-center hover:bg-[#000] transition-all duration-300 hover:text-[#fff]'>
                            
                            <p className=''>Join our Telegram</p>
                        </div>
                    </div>
                </button>
            </a>
        </motion.div>

    </motion.div>);
}

export default Header;